'use client';

import ReactQueryProvider from '../utils/react-query-provider';
import { ErrorPage } from '../layout/error-page/error-page';
import { GlobalPropsProvider } from '../context/global-props-provider';

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}
const GlobalError = ({ error }: Props) => {
  return (
    <html>
      <body style={{ margin: 0 }}>
        <GlobalPropsProvider globalProps={{ pathname: '', currentPage: 'ERROR', activeExperiments: {} }}>
          <ReactQueryProvider>
            <ErrorPage error={error} />
          </ReactQueryProvider>
        </GlobalPropsProvider>
      </body>
    </html>
  );
};

export default GlobalError;
