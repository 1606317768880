import { Button, Typography } from '@weski-monorepo/react-components';
import { useEffect } from 'react';
import WeskiHorizontal from '../../../public/weski-horizontal.svg';
import { useAnalytics } from '../../services/analytics/useAnalytics';
import { AnalyticsEvents } from '@weski-monorepo/types';
import { dataDogLog } from '../../infra/log/data-dog';

interface Props {
  error: Error;
}
export const ErrorPage = ({ error }: Props) => {
  const { sendAnalytics } = useAnalytics();

  useEffect(() => {
    void sendAnalytics(AnalyticsEvents.RENDER_ERROR, { error: error.message });
    dataDogLog.error('render error', {}, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onLinkPressed = () => {
    window.location.href = '/';
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#231f5f',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <WeskiHorizontal width={'150px'} fill={'white'} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '48px auto',
        }}
      >
        <Typography variant={'H500'}>Oops!</Typography>
        <Typography variant={'P300'}>An unknown error has occurred</Typography>
      </div>
      <Button
        variant="outlined"
        onClick={onLinkPressed}
        text={'Take me home'}
        backgroundColor={'transparent'}
        textColor={'white'}
        borderColor={'white'}
      />
    </div>
  );
};
