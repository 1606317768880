'use client';

import { ReactNode } from 'react';
import { GlobalPropsContext, GlobalPropsFromSSR } from './global-context';
import { IntlProvider } from 'react-intl';
import { ExperimentName, Variant } from '@weski-monorepo/types';

export const GlobalPropsProvider = ({
  globalProps,
  children,
}: {
  globalProps: GlobalPropsFromSSR;
  children: ReactNode;
}) => {
  const getExperimentVariable = <T extends ExperimentName>(experimentName: T): Variant<T> =>
    globalProps.activeExperiments[experimentName] as Variant<T>;

  return (
    <IntlProvider locale="en" defaultLocale="en">
      <GlobalPropsContext.Provider value={{ ...globalProps, getExperimentVariable }}>
        {children}
      </GlobalPropsContext.Provider>
    </IntlProvider>
  );
};
